<template>
  <div class="post__social--share">


    <div class="post__social--buttons">
        <a href="#" rel="noopener" class="button-social button--flex  button-twitter" @click.prevent="share(twitter)">
            <i class="uil uil-twitter-x-line button__icon">
            </i>Twitter
        </a>
        <a href="#" rel="noopener" class="button-social button--flex  button-facebook" @click.prevent="share(facebook)">
            <i class="uil uil-facebook-f button__icon">
            </i>Facebook
        </a>
        <a href="#" rel="noopener" class="button-social button--flex  button-instagram" @click.prevent="share(instagram)">
            <i class="uil uil-instagram button__icon">
            </i>Instagram
        </a>
    </div>

  </div>

</template>


// <script>


export default {
    components: {
  },
  props: {
    apiData: Object,
  },
  data() {
    return {
      providers: {
        twitter: "https://twitter.com/intent/tweet/?url=:url&text=:text",
        facebook:
          "https://www.facebook.com/sharer/sharer.php?u=:u&title=:title",
          instagram:
          "https://www.instagram.com/?url=url"
      },

      url: "",
      text: "",
    };
  },

  methods: {
    share(url) {
      window.open(url, "sharer", "toolbar=0,status=0,width=648,height=395");

      return true;
    },
  },

  created() {
    const url = `${window.location.href}`;
    // console.log(url);
    this.url = url;
    this.text = this.apiData.title;
  },

  computed: {
    facebook() {
      return this.providers.facebook
        .replace(":u", this.url)
        .replace(":title", this.text);
    },
    twitter() {
      return this.providers.twitter
        .replace(":url", this.url)
        .replace(":text", this.text);
    },
    instagram(){
        return this.providers.instagram
        .replace(":url", this.url);
    }
  },
};
</script>

<style>
.post__social--share{
  font-size: 12px !important;
}
.button-social{
   -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
    background-color: rgb(0, 182, 241);
    color: #FFF;
    padding: 0.5rem;
    font-weight: 700;
    height: 25px;
    border-color: rgba(231, 226, 226, 0.2);
    border-width: 3px;
    margin-right: 8px;


}
.button-social:hover{
  box-shadow: 0px 10px 30px rgba(57, 56, 61, 0.205) !important;
}
.button-twitter{
  background-color: rgb(0, 182, 241)
}
.button-facebook{
   background-color: #3b5998;
}
.button-instagram{
 background: #f09433;
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.share_icon_label{
    display: flex;
    justify-content: center;
}
.button__icon{
    font-size: 1.25rem;
    /* margin-right: 8px; */
    transition: .3s;
    margin: 6px 8px 6px 0px;
}
.button--flex{
    display: inline-flex;
    align-items: center;
}

</style>

